import { CircularProgress } from "@mui/material";

export const Loading = ({ isLoading }: { isLoading: boolean }) => {
    if (isLoading) {
        return (
            <div className="w-100 h-100 z-50 fixed inset-2/4">
                <CircularProgress />
            </div>
        )
    }
}