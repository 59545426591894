export const secToTime = (time: number) => {
    const seconds = Math.round(time)

    const hour = Math.floor(seconds / 3600)
    const min = Math.floor(seconds % 3600 / 60)
    const sec = seconds % 60

    if (hour !== 0) {
        return `${hour}時間${min}分${sec.toString().padStart(2, '0')}秒`
    } else if (min !== 0) {
        return `${min}分${sec.toString().padStart(2, '0')}秒`
    } else {
        return `${sec}秒`
    }
}