import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AdminSiteList, Site } from '../../Types/Site'
import { useMemo } from 'react'

export const getSiteList = async () => {
    const { data } = await axios.get('/admin_api/sites')
    return data
}

export const getSite = async (id: string): Promise<Site> => {
    const { data } = await axios.get(`/admin_api/sites/${id}`)
    return data
}

// use react-query
export const useSiteList = (): UseQueryResult<AdminSiteList[]> => {
    return useQuery({
        queryKey: ['siteList'],
        queryFn: getSiteList,
    })
}

// for user site select
export const useAdminSiteSelectOptions = () => {
    const { data: siteList, ...queryInfo } = useSiteList()

    const options = useMemo(() => {
        if (!siteList) return []

        return siteList.map((site: AdminSiteList) => (
            {
                label: site.name,
                value: site.id,
            }
        ))
    }, [siteList])

    return { options, ...queryInfo }
}